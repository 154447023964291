/* -------------------------------------------------------------------------

	PUNITED.top

	トップページのスクリプトを定義
	※jQuery必須
	Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.top) PUNITED.top = {};

(function () {
	// グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
	var ns = PUNITED.top;

	/* -----------------------------------------------------

	----------------------------------------------------- */
	ns.banner = function () {

		var $this = $('.index_banner__body');

		$this.slick({
			slidesToShow: 1,
			variableWidth: true,
			arrows: true,
			autoplay: true,
			// centerMode: true,
			// centerPadding: '0px',

			responsive: [
				{
					breakpoint: 768,
					settings: {
						centerMode: true,
					}
				}
			]
		});
	}

	ns.visual = function () {

		var $this = $('.index_visual__body');
		var $elem = $('.index_visual__content');
		var $bar = '';
		var $indexVisual = '';
		var _pagination = '';
		var _timer = '';

		function zeroPadding(num, len) {
			return (Array(len).join('0') + num).slice(- len);
		}

		function createPagination() {
			_pagination += '<div class="index_visual__pagination">';
			_pagination += '<div class="index_visual__pagination__body">';

			$elem.each(function (i) {
				_pagination += '<button class="index_visual__pagination__num">' + zeroPadding(i + 1, 2) + '</button>';
			});

			_pagination += '</div>';
			_pagination += '<div class="index_visual__pagination__footer">';
			_pagination += '<div class="index_visual__pagination__bar"></div>';
			_pagination += '</div>';
			_pagination += '</div>';

			$this.append(_pagination);
			$bar = $('.index_visual__pagination__bar');
			$dots = $('.index_visual__pagination__num');
		}

		function changeCurrent() {
			var _current = $this.slick('slickCurrentSlide') + 1;

			if (_current >= $elem.length) {
				_current = 0;
			}

			$dots.removeClass('-current').eq(_current).addClass('-current');
		}

		function changeSlide() {

			_timer = setTimeout(function () {
				$this.slick('slickNext');

				changeSlide();
			}, 5200);
		}

		$this.on('init', function () {
			createPagination();
			changeSlide();
			$dots.eq(0).addClass('-current');

			setTimeout(function () {
				$bar.addClass('-init');
			}, 1000);

			$dots.on('click', function () {

				clearTimeout(_timer);
				changeSlide();

				var _index = $(this).index();
				$this.slick('slickGoTo', _index);
				$dots.removeClass('-current').eq(_index).addClass('-current');
			});

		});

		$this.on('beforeChange', function () {
			changeCurrent();
			$bar.removeClass('-init');
		})

		$this.on('afterChange', function () {

			$bar.addClass('-init');
		})

		$this.slick({
			slidesToShow: 1,
			arrows: false,
			fade: true,
			speed: 1000
		})


	}

})();

$(function () {

	PUNITED.top.banner();
	// PUNITED.top.visual();

});
